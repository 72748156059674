import axios from 'axios';

axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL + '/api/posts';

export async function get_posts() {
    let all_posts = [];
    const response = await axios.get(BACKEND_URL).catch((error) => {
        console.error(error);
        throw error;
    });

    if (response) {
        all_posts = response.data;
        console.log(response.data);
    }

    return all_posts;
}

export async function make_post(new_post) {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Request-Method': 'POST, OPTIONS',
    };

    return await axios
        .post(BACKEND_URL, new_post, { headers })
        .then((response) => {
            return response.status === 201;
        })
        .catch((error) => {
            console.error(error);
            throw error;
        });
}

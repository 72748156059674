import { useState } from 'react';
import { CreatePostModal } from '../CreatePostModal/CreatePostModal';

function Header({ addPosts }) {
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    return (
        <div className='grid grid-flow-col bg-black text-white p-4 sticky z-50 top-0 w-full'>
            <div>Dashboard</div>
            <button
                onClick={() => setIsCreateModalOpen(true)}
                className='w-1/5 bg-white text-gray-900 rounded-2xl justify-self-end right-0'
            >
                Create Post
            </button>
            {isCreateModalOpen ? (
                <CreatePostModal
                    addPosts={addPosts}
                    setIsModalOpen={setIsCreateModalOpen}
                />
            ) : (
                ''
            )}
        </div>
    );
}

export { Header };

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { HomePage } from './pages/home';

ReactDOM.render(
  <React.StrictMode>
    <div className='App'>
      <HomePage />
    </div>
  </React.StrictMode>,
  document.getElementById('root'),
);

import { useState, useEffect } from 'react';
import { get_posts } from './../helpers/axios';
// components
import { Header } from './../components/Header/Header';
import { Post } from './../components/Post/Post';

function HomePage() {
    const [posts, setPosts] = useState([]);

    const refreshPosts = () => {
        get_posts().then((res_posts) => {
            setPosts(res_posts);
        });
    }

    const addPosts = (post) => {
        setPosts([...posts, post])
    }

    useEffect(() => {
        refreshPosts();
    }, []);

    const sortedPosts = posts.sort((a, b) => (a.time_created > b.time_created ? -1 : 1));
    return (
        <div className='HomePage bg-gray-100 h-screen'>
            <Header refreshPosts={refreshPosts} addPosts={addPosts} />
            <div>
                {sortedPosts.map((post, index) => <Post key={index} post={post} />)}
            </div>
        </div>
    );
}

export { HomePage };

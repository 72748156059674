import { useState } from 'react';

function Post({ post }) {
    const [showNotes, setShowNotes] = useState(false);

    return (
        <div className='Post'>
            <div className='bg-gray-100 flex justify-center items-center p-2'>
                <div className='max-w-xs p-2 pl-4 pr-4 container bg-white rounded-xl shadow-lg transform transition duration-500 hover:shadow-xl'>
                    <p className='text-sm leading-8 font-extrabold text-gray-900'>
                        @ {post.username}
                    </p>
                    <p className='text-sm p-1 text-gray-900'>{post.bodyText}</p>
                    {post.image ? (
                        <div className='p-1 pl-1'>
                            <img
                                className='w-full rounded-md'
                                alt='post-img'
                                src={post.image}
                            />
                        </div>
                    ) : ('')}
                    <div className='block'>
                        <button
                            className='justify-self-start text-sm font-medium'
                            onClick={() => setShowNotes(!showNotes)}
                            onMouseEnter={() => setShowNotes(true)}
                            onMouseLeave={() => setShowNotes(false)}>
                            {post.total_notes}{' '}
                            {post.total_notes <= 1 ? 'Note' : 'Notes'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Post };

import { CreatePostForm } from './CreatePostForm';

export function CreatePostModal({ setIsModalOpen, addPosts }) {
    return (
        <div className='fixed text-sm text-gray-500  flex items-center justify-center overflow-auto z-50 bg-black bg-opacity-40 left-0 right-0 top-0 bottom-0'>
            <div className='bg-white p-7 flex flex-col rounded-lg text-black'>
                <div className='grid grid-cols-2 w-full'>
                    <button
                        className='justify-self-start w-6 h-6 relative'
                        onClick={() => setIsModalOpen(false)}
                    >
                        x
                    </button>
                </div>

                <CreatePostForm
                    setIsModalOpen={setIsModalOpen}
                    addPosts={addPosts}
                />
            </div>
        </div>
    );
}

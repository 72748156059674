import { useState } from 'react';
import { make_post } from '../../helpers/axios';

function CreatePostForm({ setIsModalOpen, addPosts }) {
    const DEFAULT_DATA = {
        username: '',
        bodyText: '',
        image: '',
        time_created: Date.now(),
        total_notes: 0,
    };
    const [data, setData] = useState(DEFAULT_DATA);

    const onSubmit = async () => {
        const isSuccess = await make_post(data);
        if (isSuccess) {
            addPosts(data);
            setIsModalOpen(false);
        }
    };

    return (
        <>
            <form className='flex flex-col'>
                <span className='text-center text-lg'>Create Post</span>
                <input
                    className='shadow-gray-100 shadow-mg bg-gray-50 text-gray-800 p-2 mt-2 bg-white text-center focus:outline-none focus:ring-2 focus:ring-gray-100 focus:border-transparent rounded'
                    placeholder='Username'
                    value={data.username}
                    onChange={(e) =>
                        setData({ ...data, username: e.target.value })
                    }
                />
                <textarea
                    className='shadow-gray-100 shadow-mg bg-gray-50 text-gray-800 p-2 mt-1 bg-white focus:outline-none focus:ring-2 focus:ring-gray-100 focus:border-transparent rounded'
                    placeholder='Write your text here...'
                    value={data.bodyText}
                    onChange={(e) =>
                        setData({ ...data, bodyText: e.target.value })
                    }
                />
                <input
                    className='shadow-gray-100 shadow-mg bg-gray-50 text-gray-800 p-2 mt-1 mb-2 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:border-transparent rounded'
                    placeholder='https://example.com'
                    value={data.image}
                    onChange={(e) =>
                        setData({ ...data, image: e.target.value })
                    }
                />
            </form>
            <div className='mt-3'>
                <button
                    className='bg-black text-white rounded w-min py-1 justify-self-start px-3'
                    onClick={onSubmit}
                >
                    Add
                </button>
            </div>
        </>
    );
}

export { CreatePostForm };
